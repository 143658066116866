// ===== GENERATED FILE =====// DO NOT EDIT THIS FILE!
export default {
  "en": {
    "flag": "us",
    "name": "English"
  },
  "fr": {
    "flag": "fr",
    "name": "Français"
  }
}