<template>
  <img alt="Vue logo" src="../assets/4HM.png">
</template>

<script>
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 100%;

  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
</style>
